import React from "react";

// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';

import Layout from "../../../components/Layout/Layout";

const Article23022021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              PRELIMINARY FIGURES 2020: S&T CONTINUES PROFITABLE REVENUE GROWTH
              DESPITE CORONA CRISIS
            </h1>
            <p className="bullet">
              Revenues increased by 11.7% to EUR 1,254.8 million (PY: EUR
              1,122.9 million)
            </p>
            <p className="bullet">
              EBITDA grows by almost 16% to approx. EUR 129.5 million (PY: EUR
              111.7 million)
            </p>
            <p className="bullet">
              EPS increase by approx. 15% according to preliminary figures
            </p>
            <p className="bullet">
              Operating cash flow reaches all-time high of EUR 140.8 million
              (PY: EUR 83.4 million)
            </p>
            <p>
              According to preliminary figures, S&T AG (ISIN: AT0000A0E9W5, WKN:
              A0X9EJ, stock exchange symbol: SANT) was able to further improve
              in all parameters in the financial year 2020 despite the Covid-19
              pandemic: Revenues increased by 11.7% from EUR 1,122.9 million to
              EUR 1,254.8 million, of which around half represents organic
              growth. EBITDA rose to EUR 129.5 million (PY: EUR 111.7 million),
              which represents a disproportionate increase of around 16%. The
              EBITDA margin is thus expected to be around 10.3%. The fourth
              quarter was also very strong with revenues of EUR 418.4 million
              (PY: EUR 369.7 million) and EBITDA of EUR 45.8 million (PY: EUR
              40.0 million).{" "}
            </p>
            <p>
              According to preliminary results, earnings per share (undiluted)
              also increased by around 15%. Particularly noteworthy is the
              further improvement in operating cash flow, which reached a new
              all-time high in the history of S&T AG at around EUR 140.8 million
              or EUR 134.7 million, adjusted for the operating cash flow of
              CITYCOMP and Iskratel Group acquired in 2020. The strong cash flow
              is attributable to the successful implementation of the PEC
              program.
            </p>
            <p>
              S&T AG will publish its full and audited results for the financial
              year 2020 on March 25, 2021.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article23022021;
